$grey_bg: rgb(245, 245, 251);
$grey_input: #F5F5FB;
$grey_text: #808080;
$grey_stroke: #E3E3E3;
$grey_hover: #F4F4F4;
$grey_nav: #717171;
$green: #36B8B7;
$green_hover: #41CCCB;
$green_active: #4EE0DF;
$light_green: #00d0cd;
$opacity_green: #E1F4F4;
$opacity_green_hover: #D7EFEF;
$opacity_green_active: #C2EAEA;
$red: #c92c08;

@mixin breakpoint($class) {
  @if $class == xs {
    @media (max-width: 767px) { @content; }
  }

  @else if $class == sm {
    @media (min-width: 768px) { @content; }
  }

  @else if $class == md {
    @media (min-width: 1025px) { @content; }
  }

  @else if $class == md_down {
    @media (max-width: 1024px) { @content; }
  }

  @else if $class == lg {
    @media (min-width: 1200px) { @content; }
  }

  @else if $class == iphone_5 {
    @media only screen
      and (min-width : 320px)
      and (max-width : 340px)
      and (orientation: portrait) { @content; }
  }

  @else {
    @warn "Breakpoint mixin supports: xs, sm, md, md_down, lg, iphone_5";
  }
}

$header_height: 80px;

.close_btn_row {
  margin-bottom: 60px;
}

.sub_title {
  font-weight: 700;
  font-size: 16px;
  letter-spacing: -0.025em;
  margin: 40px 0 20px;
}

.o_list {
  padding-left: 20px;

  li {
    margin-bottom: 20px;
  }
}

.image {
  max-width: 100%;
  margin-bottom: 20px;
}

.checkmark {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #9DBBD7;
  padding-bottom: 2px;
  box-sizing: border-box;
  margin-left: 10px;
  flex-shrink: 0;

  &::before {
    content: '';
    width: 8px;
    height: 4px;
    border-left: 2px solid #fff;
    border-bottom: 2px solid #fff;
    transform: rotate(-45deg);
  }
}

.row {
  display: flex;
  align-items: center;
}

.paragraph {
  margin: 20px 0 40px;
}

.drop_down_wrapper {
  max-width: 320px;
}
