$grey_bg: rgb(245, 245, 251);
$grey_input: #F5F5FB;
$grey_text: #808080;
$grey_stroke: #E3E3E3;
$grey_hover: #F4F4F4;
$grey_nav: #717171;
$green: #36B8B7;
$green_hover: #41CCCB;
$green_active: #4EE0DF;
$light_green: #00d0cd;
$opacity_green: #E1F4F4;
$opacity_green_hover: #D7EFEF;
$opacity_green_active: #C2EAEA;
$red: #c92c08;

@mixin breakpoint($class) {
  @if $class == xs {
    @media (max-width: 767px) { @content; }
  }

  @else if $class == sm {
    @media (min-width: 768px) { @content; }
  }

  @else if $class == md {
    @media (min-width: 1025px) { @content; }
  }

  @else if $class == md_down {
    @media (max-width: 1024px) { @content; }
  }

  @else if $class == lg {
    @media (min-width: 1200px) { @content; }
  }

  @else if $class == iphone_5 {
    @media only screen
      and (min-width : 320px)
      and (max-width : 340px)
      and (orientation: portrait) { @content; }
  }

  @else {
    @warn "Breakpoint mixin supports: xs, sm, md, md_down, lg, iphone_5";
  }
}

$header_height: 80px;

.description {
  margin: 20px 0;
}

.screens_list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -20px;
}

.preview_wrapper {
  margin: 20px;
}

.preview {
  position: relative;
  width: 340px;
  height: 698px;
  box-sizing: border-box;
  padding: 50px 10px 80px;
  background-color: #E4E4F1;
  border-radius: 40px;
  overflow: hidden;
  margin: 0 auto;

  &::after {
    content: '';
    display: block;
    width: 50px;
    height: 50px;
    position: absolute;
    left: 50%;
    bottom: 15px;
    transform: translate(-50%, 0);
    border-radius: 50%;
    background-color: $grey_input;
  }
}

.screen_name {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 10px;
}

.screen_id {
  color: #717171;
  margin-bottom: 20px;
}

.iframe_wrapper {
  width: 100%;
  height: 100%;
  background-color: #fff;
  overflow: hidden;
}

.preview_iframe {
  width: 100%;
  height: 100%;
  border: none;
}

.hover_menu {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
}

.hover_menu_buttons {
  width: 85%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.menu_btn {
  position: relative;
  padding-right: 40px;
  padding-left: 40px;
  box-sizing: border-box;
  text-align: center;
}

.btn_icon {
  position: absolute;
  left: 20px;
  top: 12px;
  width: 18px;
  fill: $green;
}

.edit_screen_wrapper {
  display: flex;
  min-height: 100vh;
  align-items: stretch;
}

.edit_screen_content {
  flex: 1;
  background-color: $grey_bg;
  min-height: calc(100vh - #{$header_height});
  box-sizing: border-box;
  padding: 40px 50px;
}

.side_bar {
  width: 440px;
  box-sizing: border-box;
  padding-top: 50px;
}

.side_bar_title_box {
  padding: 0 50px;
}

.side_bar_content {
  padding: 10px 50px 30px;
}

.dropdown_toggler {
  display: flex;
  align-items: center;
  width: 100%;
  height: 50px;
  box-sizing: border-box;
  padding: 0 20px;
  border-top: 1px dashed #CCCCCC;
  border-bottom: 1px dashed #CCCCCC;
}

.dropdown_arrow {
  display: inline-block;
  width: 4px;
  height: 4px;
  border-bottom: 2px solid #000;
  border-left: 2px solid #000;
  transform: rotate(-45deg);
}

.toggler_text {
  font-weight: 700;
  font-size: 16px;
  margin-left: 10px;
}

.toggler_active {
  @extend .dropdown_toggler;

  background-color: #F1F1FC;
  border-color: transparent;

  .dropdown_arrow {
    transform: rotate(135deg);
  }
}

.iphone_icon {
  width: 20px;
  fill: #DBDBF2;
}

.ipad_icon {
  width: 28px;
  fill: #DBDBF2;
}

.active_icon {
  fill: $green;
}

.view_toggler {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  max-width: 340px;
  background-color: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  margin: 0 auto;
  box-sizing: border-box;
  padding: 7px 16px 0;
  margin-bottom: 50px;
}

.view_btn {
  width: 60px;
  box-sizing: border-box;
  padding-bottom: 4px;
  border-bottom: 2px solid transparent;
}

.active_btn {
  border-bottom-color: $green;
}

.iphone_x_wrapper {
  position: relative;
  width: 395px;
  height: 832px;
  box-sizing: border-box;
  border: 10px solid #E4E4F1;
  background-color: #E4E4F1;
  border-radius: 50px;
  overflow: hidden;
  margin: 0 auto;

  &::after {
    content: '';
    display: block;
    width: 212px;
    height: 25px;
    position: absolute;
    left: 50%;
    top: 0;
    transform: translate(-50%, 0);
    border-bottom-right-radius: 16px;
    border-bottom-left-radius: 16px;
    background-color: #E4E4F1;
  }
}

.ipad_wrapper {
  position: relative;
  width: 808px;
  height: 1154px;
  box-sizing: border-box;
  padding: 50px 20px 80px;
  background-color: #E4E4F1;
  border-radius: 40px;
  overflow: hidden;
  margin: 0 auto;

  &::after {
    content: '';
    display: block;
    width: 50px;
    height: 50px;
    position: absolute;
    left: 50%;
    bottom: 15px;
    transform: translate(-50%, 0);
    border-radius: 50%;
    background-color: $grey_input;
  }

  &::before {
    content: '';
    display: block;
    width: 10px;
    height: 10px;
    position: absolute;
    left: 50%;
    top: 20px;
    transform: translate(-50%, 0);
    border-radius: 50%;
    background-color: $grey_input;
  }
}

.copy_icon {
  width: 16px;
  fill: $green;
}

.copy_btn {
  margin-left: 5px;
}

.copied_message {
  position: fixed;
  z-index: 10;
  bottom: 20px;
  right: 20px;
  padding: 10px 20px;
  border-radius: 3px;
  background-color: #fff;
}
