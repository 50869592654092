$grey_bg: rgb(245, 245, 251);
$grey_input: #F5F5FB;
$grey_text: #808080;
$grey_stroke: #E3E3E3;
$grey_hover: #F4F4F4;
$grey_nav: #717171;
$green: #36B8B7;
$green_hover: #41CCCB;
$green_active: #4EE0DF;
$light_green: #00d0cd;
$opacity_green: #E1F4F4;
$opacity_green_hover: #D7EFEF;
$opacity_green_active: #C2EAEA;
$red: #c92c08;

@mixin breakpoint($class) {
  @if $class == xs {
    @media (max-width: 767px) { @content; }
  }

  @else if $class == sm {
    @media (min-width: 768px) { @content; }
  }

  @else if $class == md {
    @media (min-width: 1025px) { @content; }
  }

  @else if $class == md_down {
    @media (max-width: 1024px) { @content; }
  }

  @else if $class == lg {
    @media (min-width: 1200px) { @content; }
  }

  @else if $class == iphone_5 {
    @media only screen
      and (min-width : 320px)
      and (max-width : 340px)
      and (orientation: portrait) { @content; }
  }

  @else {
    @warn "Breakpoint mixin supports: xs, sm, md, md_down, lg, iphone_5";
  }
}

$header_height: 80px;

.background {
  min-height: calc(100vh - #{$header_height});
  display: flex;
  align-items: center;
  justify-content: center;
}

.tooltip {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #fff;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  padding: 2px;
}

.forms_container {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -20px;
}

.form_wrapper {
  margin: 20px;
}

.error {
  color: $red;
  padding: 10px 0;
}

.form_modal {
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 4;
  background-color: #fff;
  max-height: 85vh;
  overflow: auto;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05);
}

.form_modal_wide {
  position: absolute;
  top: 20px;
  left: 50%;
  max-width: 90%;
  min-width: 500px;
  transform: translate(-50%, 0);
  z-index: 4;
  background-color: #fff;
  max-height: 85vh;
  overflow: auto;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05);
}

.close_btn_row {
  text-align: right;
  background-color: #fff;
  padding: 10px 10px 0 0;
}

.flex_container {
  display: flex;
  justify-content: center;
  column-gap: 30px;
}
