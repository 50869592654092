$grey_bg: rgb(245, 245, 251);
$grey_input: #F5F5FB;
$grey_text: #808080;
$grey_stroke: #E3E3E3;
$grey_hover: #F4F4F4;
$grey_nav: #717171;
$green: #36B8B7;
$green_hover: #41CCCB;
$green_active: #4EE0DF;
$light_green: #00d0cd;
$opacity_green: #E1F4F4;
$opacity_green_hover: #D7EFEF;
$opacity_green_active: #C2EAEA;
$red: #c92c08;

@mixin breakpoint($class) {
  @if $class == xs {
    @media (max-width: 767px) { @content; }
  }

  @else if $class == sm {
    @media (min-width: 768px) { @content; }
  }

  @else if $class == md {
    @media (min-width: 1025px) { @content; }
  }

  @else if $class == md_down {
    @media (max-width: 1024px) { @content; }
  }

  @else if $class == lg {
    @media (min-width: 1200px) { @content; }
  }

  @else if $class == iphone_5 {
    @media only screen
      and (min-width : 320px)
      and (max-width : 340px)
      and (orientation: portrait) { @content; }
  }

  @else {
    @warn "Breakpoint mixin supports: xs, sm, md, md_down, lg, iphone_5";
  }
}

$header_height: 80px;

$grey_track: #F1F1F1;

.react-calendar {
  width: 290px;
  box-sizing: border-box;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}

.react-calendar__tile,
.react-calendar__month-view__weekdays {
  margin-bottom: 5px;
}

.react-calendar__tile--active {
  background-color: $grey_track;
}

.react-calendar__tile--rangeStart {
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  background-color: $grey_track;
}

.react-calendar__tile--rangeBothEnds {
  background-color: #fff;
}

.react-calendar__tile--rangeEnd {
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  background-color: $grey_track;
}

.react-calendar__tile--rangeStart abbr,
.react-calendar__tile--rangeEnd abbr {
  background-color: $green;
  color: #fff;
}

.react-calendar__tile abbr,
.react-calendar__month-view__weekdays__weekday abbr {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  font-weight: 500;
}

.react-calendar__navigation__label__labelText {
  font-weight: 500;
  text-transform: capitalize;
}

abbr[title] {
  text-decoration: none;
  color: $grey_nav;
  font-weight: 500;
}

.calendar_arrow_right,
.calendar_arrow_left {
  width: 10px;
}

.calendar_arrow_right {
  transform: rotate(180deg);
  padding: 5px 5px 5px 10px;
}

.calendar_arrow_left {
  padding: 5px 10px 5px 5px;
}

.react-calendar__month-view__weekdays__weekday abbr {
  text-transform: capitalize;
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: $grey_text;
}
