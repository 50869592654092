.btn_grn {
  color: #fff;
  background-color: $green;
  font-weight: 700;
  border-radius: 3px;
  padding: 12px 2px;

  &:hover {
    opacity: 0.9;
  }

  &:active {
    opacity: 0.8;
  }
}

.btn_grn_full_width {
  @extend .btn_grn;

  width: 100%;
  display: block;
  text-align: center;
}

.btn_grn_wide {
  @extend .btn_grn;

  display: inline-block;
  padding: 12px 50px;
}

.btn_grn_small {
  display: flex;
  align-items: center;
  padding: 8px 15px;
  color: #fff;
  font-weight: 500;
  background-color: $green;
  border-radius: 3px;

  &:hover {
    opacity: 0.9;
  }

  &:active {
    opacity: 0.8;
  }
}

.btn_grn_small_full_width {
  @extend .btn_grn_small;

  width: 100%;
}

.btn_create {
  display: flex;
  align-items: center;
  padding: 8px 15px;
  color: #fff;
  font-weight: 500;
  background-color: $green;
  border-radius: 3px;

  &::before {
    content: '+';
    display: flex;
    font-size: 20px;
    line-height: 17px;
    margin-right: 10px;
  }

  &:hover {
    opacity: 0.9;
  }

  &:active {
    opacity: 0.8;
  }
}

.btn_white {
  color: $green;
  background-color: #fff;
  font-weight: 500;
  border-radius: 3px;
  padding: 12px 2px;
  font-size: 16px;
}

.btn_white_full_width {
  @extend .btn_white;

  width: 100%;
  display: block;

  &.spaced {
    margin: 15px auto;
  }
}

.btn_white_bordered {
  color: $green;
  background-color: #fff;
  border-radius: 3px;
  padding: 12px 2px;
  font-size: 14px;
  border: 1px solid $green;
  width: 100%;
  display: block;

  &:hover {
    background-color: $opacity_green_hover;
  }

  &:active {
    background-color: $opacity_green_active;
  }
}

.back_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 34px;
  height: 34px;
  background-color: $green;
  border-radius: 3px;
  margin-right: 20px;
  flex-shrink: 0;

  &::before {
    content: '';
    display: block;
    width: 8px;
    height: 8px;
    border-bottom: 3px solid #fff;
    border-left: 3px solid #fff;
    transform: rotate(45deg);
  }

  &:hover {
    opacity: 0.9;
  }

  &:active {
    opacity: 0.8;
  }
}

.btn_light_grn {
  font-size: 13px;
  color: $green;
  background-color: $opacity_green;
  padding: 5px 15px;
  border-radius: 3px;

  &:hover {
    background-color: $opacity_green_hover;
  }

  &:active {
    background-color: $opacity_green_active;
  }
}

.close_icon {
  position: relative;
  top: 6px;
  left: 6px;
  width: 16px;
  height: 16px;

  &::before,
  &::after {
    content: '';
    display: block;
    width: 22px;
    height: 3px;
    background-color: #fff;
    position: absolute;
    top: 0;
    left: 0;
  }

  &::before {
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
  }
}

.close_btn {
  width: 34px;
  height: 34px;
  background-color: $green;
  border-radius: 3px;

  &:hover {
    opacity: 0.9;
  }

  &:active {
    opacity: 0.8;
  }
}

.open_eye_icon {
  &::before {
    content: '';
    display: block;
    position: absolute;
    width: 4px;
    height: 4px;
    top: 6px;
    left: 50%;
    transform: translate(-50%, 0);
    border-radius: 50%;
    background-color: $green;
  }
}

.plus_btn {
  white-space: nowrap;

  &::before {
    content: '+';
    display: inline-block;
    width: 30px;
    height: 30px;
    text-align: center;
    line-height: 28px;
    background-color: $opacity_green;
    color: $green;
    font-size: 20px;
    border-radius: 50%;
  }

  &:hover::before {
    background-color: $opacity_green_hover;
  }

  &:active::before {
    background-color: $opacity_green_active;
  }
}
