.settings_nav {
  padding: 0 50px;
  margin: 30px -50px 25px;
  border-bottom: 1px solid $grey_stroke;
}

.nav_link {
  padding: 10px 0 7px;
  font-size: 16px;
  font-weight: 500;
  color: $grey_nav;
  display: inline-block;
  border-bottom: 3px solid transparent;

  &:not(:last-child) {
    margin-right: 30px;
  }

  &:hover {
    color: #000;
  }
}

.nav_link_active {
  border-bottom: 3px solid $green;
  color: #000;
  font-weight: 700;
}

.nav_link_disabled {
  @extend .nav_link;

  pointer-events: none;
  color: $grey-stroke;
}
