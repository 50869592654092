$grey_bg: rgb(245, 245, 251);
$grey_input: #F5F5FB;
$grey_text: #808080;
$grey_stroke: #E3E3E3;
$grey_hover: #F4F4F4;
$grey_nav: #717171;
$green: #36B8B7;
$green_hover: #41CCCB;
$green_active: #4EE0DF;
$light_green: #00d0cd;
$opacity_green: #E1F4F4;
$opacity_green_hover: #D7EFEF;
$opacity_green_active: #C2EAEA;
$red: #c92c08;

@mixin breakpoint($class) {
  @if $class == xs {
    @media (max-width: 767px) { @content; }
  }

  @else if $class == sm {
    @media (min-width: 768px) { @content; }
  }

  @else if $class == md {
    @media (min-width: 1025px) { @content; }
  }

  @else if $class == md_down {
    @media (max-width: 1024px) { @content; }
  }

  @else if $class == lg {
    @media (min-width: 1200px) { @content; }
  }

  @else if $class == iphone_5 {
    @media only screen
      and (min-width : 320px)
      and (max-width : 340px)
      and (orientation: portrait) { @content; }
  }

  @else {
    @warn "Breakpoint mixin supports: xs, sm, md, md_down, lg, iphone_5";
  }
}

$header_height: 80px;

@import './table';
@import './buttons.scss';
@import './nav.scss';

.title {
  font-weight: 700;
  font-size: 30px;
  letter-spacing: -0.05em;
}

.sub_title {
  font-weight: 700;
  font-size: 24px;
  letter-spacing: -0.05em;
}

.input {
  background-color: $grey_input;
  width: 100%;
  border-radius: 3px;
  border: none;
  height: 40px;
  box-sizing: border-box;
  padding: 0 7px;
  font-size: 14px;
}

.input_w_icon {
  @extend .input;

  padding-right: 35px;
}

@font-face {
  font-family: 'dotsfont';
  src: url('~assets/fonts/dotsfont.eot');
  src: url('~assets/fonts/dotsfont.eot?#iefix')  format('embedded-opentype'),
    url('~assets/fonts/dotsfont.svg#dotsfontregular')    format('svg'),
    url('~assets/fonts/dotsfont.woff')        format('woff'),
    url('~assets/fonts/dotsfont.ttf')         format('truetype');
  font-weight: normal;
  font-style: normal;
}

.secret_input {
  -webkit-text-security: disc;
  font-family: 'dotsfont', sans-serif;
}

.input_w_icon_wrapper {
  position: relative;
}

.input_icn_btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
}

.input_icon {
  width: 16px;
  fill: $green;

  &:hover {
    fill: $green_hover;
  }

  &:active {
    fill: $green_active;
  }
}

.label {
  margin-bottom: 7px;
}

.label_row {
  @extend .label;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.form_background {
  background-color: $grey_bg;
  min-height: 100vh;
  box-sizing: border-box;
  padding: 20px 0;

  &::after {
    content: '';
    display: block;
    width: 100%;
    height: 1000px;
    max-width: 840px;
    max-height: 90vh;
    background-image: url('~assets/images/dots_bg.png');
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.form_wrapper {
  width: 300px;
  margin: 0 auto;
  background: #FFFFFF;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  box-sizing: border-box;
  padding: 30px 30px 20px;
  position: relative;
  z-index: 2;
}

.form_wrapper_static {
  @extend .form_wrapper;

  position: static;
}

.form_wrapper_left {
  @extend .form_wrapper_static;

  margin: 0;
}

.form_wrapper_wide {
  @extend .form_wrapper_static;

  width: 380px;
  margin: 0;
}

.link {
  color: $green;

  &:hover {
    color: $green_hover;
  }

  &:active {
    color: $green_active;
  }
}

.content {
  background-color: $grey_bg;
  min-height: calc(100vh - #{$header_height});
  box-sizing: border-box;
  padding: 40px 50px;
}

.title_row {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
}

.title_btn_box, .title_sort_box {
  margin-left: 30px;
}

.bold {
  font-weight: 700;
}

.text_area {
  background-color: $grey_input;
  width: 100%;
  resize: vertical;
  border-radius: 3px;
  border: none;
  min-height: 40px;
  box-sizing: border-box;
  padding: 12px 7px;
  font-size: 14px;

  &:focus {
    outline: none;
  }
}

.sub_row {
  margin-top: 40px;
  margin-bottom: 10px;
}
