$grey_bg: rgb(245, 245, 251);
$grey_input: #F5F5FB;
$grey_text: #808080;
$grey_stroke: #E3E3E3;
$grey_hover: #F4F4F4;
$grey_nav: #717171;
$green: #36B8B7;
$green_hover: #41CCCB;
$green_active: #4EE0DF;
$light_green: #00d0cd;
$opacity_green: #E1F4F4;
$opacity_green_hover: #D7EFEF;
$opacity_green_active: #C2EAEA;
$red: #c92c08;

@mixin breakpoint($class) {
  @if $class == xs {
    @media (max-width: 767px) { @content; }
  }

  @else if $class == sm {
    @media (min-width: 768px) { @content; }
  }

  @else if $class == md {
    @media (min-width: 1025px) { @content; }
  }

  @else if $class == md_down {
    @media (max-width: 1024px) { @content; }
  }

  @else if $class == lg {
    @media (min-width: 1200px) { @content; }
  }

  @else if $class == iphone_5 {
    @media only screen
      and (min-width : 320px)
      and (max-width : 340px)
      and (orientation: portrait) { @content; }
  }

  @else {
    @warn "Breakpoint mixin supports: xs, sm, md, md_down, lg, iphone_5";
  }
}

$header_height: 80px;

.plans_row {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-top: 40px;
  max-width: 880px;
}

.plan_header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  min-height: 120px;
  box-sizing: border-box;
  padding: 25px 15px 5px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.plan_wrapper {
  display: flex;
  flex-direction: column;
  width: 280px;
  min-height: 520px;
  box-sizing: border-box;
  background-color: #fff;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  padding: 5px;
  margin-bottom: 20px;

  &:nth-child(1) {
    .plan_header {
      background-color: #F0F0F0;
    }
  }

  &:nth-child(2) {
    .plan_header {
      background-color: #E1FCE1;
    }
  }

  &:nth-child(3) {
    .plan_header {
      background-color: #E0F6FF;
    }
  }
}

.plan_title {
  font-size: 24px;
  font-weight: 700;
  padding-bottom: 10px;
}

.plan_price {
  font-size: 36px;

  span,
  sup {
    font-size: 14px;
    padding-right: 3px;
  }
}

.plan_features {
  li {
    display: flex;

    &:not(:last-child) {
      padding-bottom: 20px;
    }
  }
}

.checkmark {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #9DBBD7;
  padding-bottom: 2px;
  box-sizing: border-box;
  margin-right: 10px;
  flex-shrink: 0;

  &::before {
    content: '';
    width: 8px;
    height: 4px;
    border-left: 2px solid #fff;
    border-bottom: 2px solid #fff;
    transform: rotate(-45deg);
  }
}

.plan_content {
  padding: 20px;
}

.man_img {
  width: 38px;
}

.girl_img {
  width: 54px;
}

.people_img {
  width: 80px;
}

.divider {
  border-bottom: 1px dashed #CCCCCC;
  margin: 20px 0;
}

.btn_box {
  padding: 0 20px 15px;
  margin-top: auto;
}

.description {
  padding-top: 20px;
}

.activated {
  text-align: center;
  font-weight: 700;
  color: $grey_text;
  padding-bottom: 10px;
}
