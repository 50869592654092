$grey_bg: rgb(245, 245, 251);
$grey_input: #F5F5FB;
$grey_text: #808080;
$grey_stroke: #E3E3E3;
$grey_hover: #F4F4F4;
$grey_nav: #717171;
$green: #36B8B7;
$green_hover: #41CCCB;
$green_active: #4EE0DF;
$light_green: #00d0cd;
$opacity_green: #E1F4F4;
$opacity_green_hover: #D7EFEF;
$opacity_green_active: #C2EAEA;
$red: #c92c08;

@mixin breakpoint($class) {
  @if $class == xs {
    @media (max-width: 767px) { @content; }
  }

  @else if $class == sm {
    @media (min-width: 768px) { @content; }
  }

  @else if $class == md {
    @media (min-width: 1025px) { @content; }
  }

  @else if $class == md_down {
    @media (max-width: 1024px) { @content; }
  }

  @else if $class == lg {
    @media (min-width: 1200px) { @content; }
  }

  @else if $class == iphone_5 {
    @media only screen
      and (min-width : 320px)
      and (max-width : 340px)
      and (orientation: portrait) { @content; }
  }

  @else {
    @warn "Breakpoint mixin supports: xs, sm, md, md_down, lg, iphone_5";
  }
}

$header_height: 80px;

.content {
  position: relative;
}

.text_center {
  text-align: center;
}

.row {
  display: block;
  padding-top: 20px;
}

.submit_box {
  margin-top: 30px;
}

.error_message {
  color: $red;
  padding-top: 10px;
}

.background {
  min-height: calc(100vh - #{$header_height});
  display: flex;
  align-items: center;
  justify-content: center;
}

.small_text {
  font-size: 10px;
}

.description {
  font-size: 11px;
  color: $grey-text;
  padding-top: 10px;
}

.file_input {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.file_btn {
  position: relative;
  background-color: $light_green;
  border-radius: 3px;
  color: #fff;
  display: inline-flex;
  align-items: center;
  padding: 8px 10px;
  box-sizing: border-box;
  min-width: 120px;
  min-height: 34px;

  &:hover {
    opacity: 0.9;
  }

  &:active {
    opacity: 0.8;
  }
}

.upload_icon {
  width: 18px;
  fill: #fff;
  margin-right: 10px;
}

.upload_text {
  font-weight: 500;
}

.file_row {
  display: flex;
  align-items: center;
}

.file_name {
  color: $grey_text;
  margin-left: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
}

.btns_box {
  max-width: 320px;
  margin: 0 auto;
  padding-bottom: 20px;
}
