$grey_bg: rgb(245, 245, 251);
$grey_input: #F5F5FB;
$grey_text: #808080;
$grey_stroke: #E3E3E3;
$grey_hover: #F4F4F4;
$grey_nav: #717171;
$green: #36B8B7;
$green_hover: #41CCCB;
$green_active: #4EE0DF;
$light_green: #00d0cd;
$opacity_green: #E1F4F4;
$opacity_green_hover: #D7EFEF;
$opacity_green_active: #C2EAEA;
$red: #c92c08;

@mixin breakpoint($class) {
  @if $class == xs {
    @media (max-width: 767px) { @content; }
  }

  @else if $class == sm {
    @media (min-width: 768px) { @content; }
  }

  @else if $class == md {
    @media (min-width: 1025px) { @content; }
  }

  @else if $class == md_down {
    @media (max-width: 1024px) { @content; }
  }

  @else if $class == lg {
    @media (min-width: 1200px) { @content; }
  }

  @else if $class == iphone_5 {
    @media only screen
      and (min-width : 320px)
      and (max-width : 340px)
      and (orientation: portrait) { @content; }
  }

  @else {
    @warn "Breakpoint mixin supports: xs, sm, md, md_down, lg, iphone_5";
  }
}

$header_height: 80px;

.menu_wrapper {
  width: 80%;
  position: relative;
  padding: 12px 0;
  box-sizing: border-box;
}

.drop_down {
  width: 220px;
  max-height: 50vh;
  overflow: auto;
  position: absolute;
  top: 45px;
  right: 9px;
  transform: translate(50%, 0);
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  border: 1px solid $grey_stroke;
  font-size: 12px;
  z-index: 3;

  &::before {
    content: '';
    position: absolute;
    top: -8px;
    left: calc(50% - 12px);
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 8px 8px 8px;
    border-color: transparent transparent $grey_stroke transparent;
  }

  &::after {
    content: '';
    position: absolute;
    top: -7px;
    left: calc(50% - 13px);
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 9px 10px 9px;
    border-color: transparent transparent #fff transparent;
  }
}

.menu_item {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 8px 20px;
  box-sizing: border-box;

  &:hover {
    background-color: $grey_hover;
  }
}

.app_logo {
  width: 28px;
  height: 28px;
  border-radius: 4px;
  background-color: $grey_bg;
  background-repeat: no-repeat;
  background-size: cover;
  margin-right: 10px;
  flex-shrink: 0;
}

.arrow_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: transparent;
  box-sizing: border-box;
  padding-bottom: 2px;
  flex-shrink: 0;

  &::after {
    content: '';
    display: inline-block;
    width: 6px;
    height: 6px;
    border-right: 2px solid #000;
    border-bottom: 2px solid #000;
    transform: rotate(45deg);
    flex-shrink: 0;
  }
}

.toggler {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-height: 65px;
  overflow: hidden;
  padding-right: 3px;

  &:hover {
    .arrow_wrapper {
      background-color: $opacity_green_hover;
    }
  }

  &:active {
    .arrow_wrapper {
      background-color: $opacity_green_active;
    }
  }
}

.placeholder {
  padding: 8px 20px;
}
