$grey_bg: rgb(245, 245, 251);
$grey_input: #F5F5FB;
$grey_text: #808080;
$grey_stroke: #E3E3E3;
$grey_hover: #F4F4F4;
$grey_nav: #717171;
$green: #36B8B7;
$green_hover: #41CCCB;
$green_active: #4EE0DF;
$light_green: #00d0cd;
$opacity_green: #E1F4F4;
$opacity_green_hover: #D7EFEF;
$opacity_green_active: #C2EAEA;
$red: #c92c08;

@mixin breakpoint($class) {
  @if $class == xs {
    @media (max-width: 767px) { @content; }
  }

  @else if $class == sm {
    @media (min-width: 768px) { @content; }
  }

  @else if $class == md {
    @media (min-width: 1025px) { @content; }
  }

  @else if $class == md_down {
    @media (max-width: 1024px) { @content; }
  }

  @else if $class == lg {
    @media (min-width: 1200px) { @content; }
  }

  @else if $class == iphone_5 {
    @media only screen
      and (min-width : 320px)
      and (max-width : 340px)
      and (orientation: portrait) { @content; }
  }

  @else {
    @warn "Breakpoint mixin supports: xs, sm, md, md_down, lg, iphone_5";
  }
}

$header_height: 80px;

.logo {
  width: 70px;
  fill: #000;
  margin: 24px 4px 60px 50px;
}

.sidebar {
  width: 240px;
  background-color: #fff;
  border-right: 1px solid $grey_stroke;
}

.navlink {
  display: block;
  box-sizing: border-box;
  padding: 12px 4px 12px 45px;
  border-left: 5px solid transparent;
  transition: background-color 350ms ease;
  color: $grey_nav;

  &:hover {
    background-color: $grey_hover;
  }
}

.navlink_active {
  border-left: 5px solid $green;
  color: #000;
}

.nav_title {
  margin: 0 4px 20px 50px;
}
