.table_wrapper {
  max-width: 820px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  background-color: #fff;
  overflow: auto;
  box-sizing: border-box;
}

.table_row {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.table_cell_60 {
  width: 60%;

  @include breakpoint(xs) {
    width: 100%;
    margin-bottom: 10px;
  }
}

.table_cell_40 {
  width: 40%;

  @include breakpoint(xs) {
    width: 100%;
    margin-bottom: 10px;
  }
}

.table_cell_20 {
  width: 20%;
  padding: 0 3px;
  box-sizing: border-box;

  @include breakpoint(xs) {
    width: 100%;
    margin-bottom: 10px;
  }
}

.sticky_col {
  position: sticky;
  left: 0;
  background-color: #fff;
  outline: 1px solid $grey_stroke;
  z-index: 4;
}

.table_head {
  position: relative;
  background-color: #FCFCFF;
  border-bottom: 1px solid $grey_stroke;

  th {
    position: sticky;
    top: 0;
    z-index: 3;
    background-color: #fff;
    min-width: 20px;
    padding: 7px 15px;
    text-align: left;
  }

  th:first-child {
    border-top-left-radius: 5px;
  }

  th:last-child {
    border-top-right-radius: 5px;
  }

  .sticky_col {
    z-index: 4;
  }
}

.table_content {
  padding: 12px 14px;

  td {
    padding: 10px ;
    max-width: 320px;
    overflow: auto;
  }

  tr {
    &:not(:last-child) {
      border-bottom: 1px solid $grey_stroke;
    }
  }

  .over_flow_visible {
    overflow: visible;
  }
}

.table_item {
  padding: 12px 6px;

  &:not(:last-child) {
    border-bottom: 1px solid $grey_stroke;
  }

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    padding-bottom: 0;
  }
}

.row_logo {
  width: 40px;
  height: 40px;
  border-radius: 4px;
  background-color: $grey_nav;
  background-repeat: no-repeat;
  background-size: cover;
  margin-right: 20px;
  flex-shrink: 0;
}

.user_role {
  color: $grey_nav;
  margin-left: 10px;
}

.btn_margin {
  margin-right: 10px;
}

.cell {
  border: 1px so
}

.real_table_wrapper {
  overflow-x: auto;
  box-sizing: border-box;
  border-collapse: collapse;
  width: 100%;
}

.table_scroll_wrapper {
  width: 100%;
  min-width: 300px;
  max-height: 75vh;
  overflow-x: auto;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  background-color: #fff;
}
