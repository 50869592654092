$grey_bg: rgb(245, 245, 251);
$grey_input: #F5F5FB;
$grey_text: #808080;
$grey_stroke: #E3E3E3;
$grey_hover: #F4F4F4;
$grey_nav: #717171;
$green: #36B8B7;
$green_hover: #41CCCB;
$green_active: #4EE0DF;
$light_green: #00d0cd;
$opacity_green: #E1F4F4;
$opacity_green_hover: #D7EFEF;
$opacity_green_active: #C2EAEA;
$red: #c92c08;

@mixin breakpoint($class) {
  @if $class == xs {
    @media (max-width: 767px) { @content; }
  }

  @else if $class == sm {
    @media (min-width: 768px) { @content; }
  }

  @else if $class == md {
    @media (min-width: 1025px) { @content; }
  }

  @else if $class == md_down {
    @media (max-width: 1024px) { @content; }
  }

  @else if $class == lg {
    @media (min-width: 1200px) { @content; }
  }

  @else if $class == iphone_5 {
    @media only screen
      and (min-width : 320px)
      and (max-width : 340px)
      and (orientation: portrait) { @content; }
  }

  @else {
    @warn "Breakpoint mixin supports: xs, sm, md, md_down, lg, iphone_5";
  }
}

$header_height: 80px;

.filters_section {
  position: relative;
  margin-bottom: 40px;
}

.menu {
  position: absolute;
  top: 50px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  border: 1px solid $grey_stroke;
  z-index: 4;
}

.input_filter {
  padding: 20px;
  border-top: 1px solid $grey_stroke;
  border-bottom: 1px solid $grey_stroke;
}

.filters_row {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 10px;
}

.cancels_box {
  flex-grow: 1;
  padding: 0 10px;
}

.input_label {
  font-weight: 500;
  margin-bottom: 7px;
}

.selected_filter {
  display: inline-block;
  border-radius: 63px;
  border: 1px solid $green;
  color: $grey_nav;
  padding: 7px 10px 7px 8px;
  margin-bottom: 10px;

  &:not(:last-child) {
    margin-right: 20px;
  }
}

.cancel_icon {
  position: relative;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: $green;
  margin-right: 10px;

  &::before,
  &::after {
    content: '';
    display: block;
    position: absolute;
    width: 9px;
    height: 2px;
    background-color: #fff;
  }

  &::before {
    transform: rotate(45deg);
    top: 8px;
    left: 4px;
  }

  &::after {
    transform: rotate(-45deg);
    top: 8px;
    left: 4px;
  }
}

.row {
  display: flex;
  align-items: center;
}

.menu_item_wrapper {
  position: relative;
}

.menu_item {
  display: block;
  padding: 20px;
  background-color: #fff;
  font-weight: 500;

  &:hover {
    background-color: $grey_hover;
  }
}

.menu_item_w_arrow {
  @extend .menu_item;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;

  &::after {
    content: '';
    display: block;
    width: 8px;
    height: 8px;
    border-top: 2px solid #000;
    border-right: 2px solid #000;
    transform: rotate(45deg);
  }
}

.calendar_wrapper {
  position: absolute;
  top: 0;
  left: 100%;
}

.arrow_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  flex-shrink: 0;
  margin-left: 4px;
}

.arrow_wrapper_hidden {
  @extend .arrow_wrapper;

  background-color: $opacity_green_hover;
  opacity: 0;
  transition: opacity 250ms ease;
}

.arrow_down {
  width: 12px;

  path {
    stroke: #000;
  }
}

.arrow_up {
  @extend .arrow_down;

  transform: rotate(180deg);
}

.sort_cell {
  display: flex;
  align-items: center;
  font-weight: 700;
  cursor: pointer;

  &:hover {
    .arrow_wrapper {
      background-color: $opacity_green_hover;
    }

    .arrow_down path {
      stroke: $green;
    }

    .arrow_wrapper_hidden {
      opacity: 1;
    }
  }

  &:active {
    .arrow_wrapper {
      background-color: $opacity_green_hover;
    }

    .arrow_down path {
      stroke: #000;
    }

    .arrow_wrapper_hidden {
      opacity: 1;
    }
  }
}
