$grey_bg: rgb(245, 245, 251);
$grey_input: #F5F5FB;
$grey_text: #808080;
$grey_stroke: #E3E3E3;
$grey_hover: #F4F4F4;
$grey_nav: #717171;
$green: #36B8B7;
$green_hover: #41CCCB;
$green_active: #4EE0DF;
$light_green: #00d0cd;
$opacity_green: #E1F4F4;
$opacity_green_hover: #D7EFEF;
$opacity_green_active: #C2EAEA;
$red: #c92c08;

@mixin breakpoint($class) {
  @if $class == xs {
    @media (max-width: 767px) { @content; }
  }

  @else if $class == sm {
    @media (min-width: 768px) { @content; }
  }

  @else if $class == md {
    @media (min-width: 1025px) { @content; }
  }

  @else if $class == md_down {
    @media (max-width: 1024px) { @content; }
  }

  @else if $class == lg {
    @media (min-width: 1200px) { @content; }
  }

  @else if $class == iphone_5 {
    @media only screen
      and (min-width : 320px)
      and (max-width : 340px)
      and (orientation: portrait) { @content; }
  }

  @else {
    @warn "Breakpoint mixin supports: xs, sm, md, md_down, lg, iphone_5";
  }
}

$header_height: 80px;

.modal_overlay {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  height: 100%;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0);
  z-index: 10;
  overflow-y: auto;
  overflow-x: hidden;
  transform: translateX(100%);
  transition: background-color 350ms ease-in-out 550ms, transform 700ms ease-in-out;
}

.modal_overlay.ReactModal__Overlay--after-open {
  background-color: rgba(0, 0, 0, 0.4);
  transform: translateX(0);
}

.modal_overlay.ReactModal__Overlay--before-close {
  background-color: rgba(0, 0, 0, 0);
  transform: translateX(100%);
}

.modal_content {
  position: relative;
  width: 65%;
  max-width: 940px;
  padding: 50px 70px;
  min-height: 100vh;
  box-sizing: border-box;
  margin-left: auto;
  background-color: #fff;

  @include breakpoint(xs) {
    width: 100%;
  }
}
