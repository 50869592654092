$grey_bg: rgb(245, 245, 251);
$grey_input: #F5F5FB;
$grey_text: #808080;
$grey_stroke: #E3E3E3;
$grey_hover: #F4F4F4;
$grey_nav: #717171;
$green: #36B8B7;
$green_hover: #41CCCB;
$green_active: #4EE0DF;
$light_green: #00d0cd;
$opacity_green: #E1F4F4;
$opacity_green_hover: #D7EFEF;
$opacity_green_active: #C2EAEA;
$red: #c92c08;

@mixin breakpoint($class) {
  @if $class == xs {
    @media (max-width: 767px) { @content; }
  }

  @else if $class == sm {
    @media (min-width: 768px) { @content; }
  }

  @else if $class == md {
    @media (min-width: 1025px) { @content; }
  }

  @else if $class == md_down {
    @media (max-width: 1024px) { @content; }
  }

  @else if $class == lg {
    @media (min-width: 1200px) { @content; }
  }

  @else if $class == iphone_5 {
    @media only screen
      and (min-width : 320px)
      and (max-width : 340px)
      and (orientation: portrait) { @content; }
  }

  @else {
    @warn "Breakpoint mixin supports: xs, sm, md, md_down, lg, iphone_5";
  }
}

$header_height: 80px;

.text_center {
  text-align: center;
}

.row {
  display: block;
  padding-top: 20px;
}

.flex_row {
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
}

.small_text {
  font-size: 11px;
}

.submit_box {
  margin-top: 30px;
}

.error_message {
  color: $red;
  padding-top: 10px;
}

.logo_box {
  margin: 30px auto 70px;
  position: relative;
  z-index: 1;
  text-align: center;
}

.logo {
  width: 70px;
  fill: #000;
}

.forgot_password_link {
  font-size: 11px;
  margin-top: 10px;
}
